import styled from "styled-components";

export const Container = styled.section`
  width: 100vw;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--beige);
  margin-top: 8rem;

  @media screen and (max-width: 768px) {
    margin-top: 5rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem 0;
  background-color: var(--beige);

  @media screen and (max-width: 768px) {
    margin: 0 5%;
  }
`;

export const TextContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  h2 {
    margin: 0;
  }

  svg {
    transform: translateX(-8px);
    transition: all 0.3s ease;
    fill: var(--black);
  }

  &:hover svg {
    transform: translateX(0);
  }

  &:active svg {
    transform: scale(0.9);
  }

  .underline-animation {
    position: relative;
    padding-bottom: 0.5rem;
  }

  .underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--blue);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover .underline-animation:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;
