import styled from "styled-components";

export const AboutContainer = styled.div`
  position: relative;
  background-color: var(--beige);
  padding: 12vh 5%;

  @media screen and (max-width: 768px) {
    padding: 12vh 5%;
  }
`;

export const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

// Heading title
export const IntroWrapper = styled.div`
  width: 100%;
  margin: 5% 0;

  h1 {
    line-height: 1.2;
    margin: 0;
    z-index: 10;

    span {
      position: relative;
      background: url("/images/line.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 15%;
    }
  }

  @media screen and (max-width: 428px) {
    h1 {
      font-size: 2.369rem;
    }
  }
`;

export const AboutContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;

  h6 {
    min-width: 300px;
  }

  a {
    font-weight: 600;
    color: var(--black);
    transition: ease-in-out 0.3s;

    &:hover {
      color: var(--blue);
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0;

    h6 {
      margin-top: 2rem;
      width: 100%;
      /* max-width: 30rem; */
    }
  }
`;

export const ImageContainer = styled.div`
  border-radius: 5px;
  min-width: 400px;
  display: flex;
  align-items: stretch;

  img {
    width: 400px;
    object-fit: cover;
    height: auto;
    border-radius: 5px;
  }

  @media screen and (max-width: 768px) {
    min-width: 200px;

    img {
      width: 100%;
      height: 26rem;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 428px) {
    img {
      width: 100%;
      height: 18rem;
      object-fit: cover;
    }
  }
`;

// Skills section
export const SkillsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 5rem;
  margin: 10% 0 5% 0;

  h3,
  h4 {
    margin: 0;
  }

  h6 {
    margin-top: 1rem;
  }

  p,
  li {
    list-style: none;
    padding: 0;
    color: var(--black);
    line-height: 1.8;
  }

  li::before {
    content: "• ";
    color: var(--blue);
  }

  @media screen and (max-width: 768px) {
    gap: 3rem;
    flex-direction: column;
    margin: 15% 0;
  }
`;

export const SkillsDescription = styled.div`
  width: 50%;
  padding-top: 1rem;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ListWrapper = styled.div`
  width: 50%;

  gap: 5rem;
  @media screen and (max-width: 768px) {
    gap: 8rem;
  }
`;

export const TechDescription = styled.div``;

export const SoftwareDescription = styled.div``;
