// array of mini projects for row on "All Work" page
export const minis = [
  {
    id: 1,
    name: "Magic 8 Ball",
    description:
      "A JavaScript Magic 8 Ball with a nostalgic Gameboy interface and custom CSS styling",
    link: "https://github.com/itannady/magic-8-ball",
    // images
    heroImgSrc: "/images/miniProjects/magic8.jpg",
  },
  {
    id: 2,
    name: "Calculator",
    description:
      "A calculator web app with realistic styling using CSS and vanilla Javascript",
    link: "https://github.com/itannady/calcStarter",
    // images
    heroImgSrc: "/images/miniProjects/calculator.jpg",
  },
  {
    id: 3,
    name: "Quote Generator",
    description:
      "Displays inspiring quotes with randomly generated scenic backgrounds ",
    link: "https://github.com/itannady/random-quote-generator/tree/main",
    // images
    heroImgSrc: "/images/miniProjects/quoteGenerator.jpg",
  },
];
