import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Nav,
  NavLogo,
  HamburgerContainer,
  HamburgerIcon,
  NavMenu,
  NavLinks,
} from "./NavbarStyle";
import Sidebar from "../Sidebar/Sidebar";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  // sidebar state
  const [isOpen, setIsOpen] = useState(false);
  // keep track of the current screen width
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // update the screen width whenever window is resized
  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth >= 768) {
      setIsOpen(false);
    }
  }, [screenWidth]);

  // changes nav color based on page/pathname
  const [pathname, setPathname] = useState("");
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  // navbar scrolling state
  const prevScrollPosRef = useRef(0);
  const [visible, setVisible] = useState(true);

  // navbar disappears on scroll, and reappears when scrolling up
  const handleScroll = useCallback(() => {
    if (!isOpen) {
      const currentScrollPos = window.pageYOffset;
      setVisible(
        prevScrollPosRef.current > currentScrollPos || currentScrollPos === 0
      );
      prevScrollPosRef.current = currentScrollPos;
    }
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  // hamburger menu toggle
  const toggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  };

  // close side bar if it is open (for logo)
  function closeSidebar() {
    setIsOpen(false);
    document.body.style.overflow = "visible";
  }

  return (
    <>
      <Nav pathname={pathname} visible={visible}>
        <NavLogo onClick={closeSidebar} to="/">
          <img src="/images/logo.png" alt="Self portrait illustration"></img>
        </NavLogo>
        {screenWidth <= 768 ? (
          <HamburgerContainer onClick={toggle}>
            <HamburgerIcon isOpen={isOpen}>
              <svg viewBox="0 0 32 32">
                <path
                  className="line line-top-bottom"
                  d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
                ></path>
                <path className="line" d="M7 16 27 16"></path>
              </svg>
            </HamburgerIcon>
          </HamburgerContainer>
        ) : null}
        {screenWidth >= 768 ? (
          <NavMenu className="navLinks">
            <NavLinks to="/work">Work</NavLinks>
            <NavLinks to="/about">About</NavLinks>
            <NavLinks to="/creations">Creations</NavLinks>
          </NavMenu>
        ) : null}
        <Sidebar pathname={pathname} isOpen={isOpen} toggle={toggle} />
      </Nav>
    </>
  );
};

export default Navbar;
