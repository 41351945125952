import React, { useRef } from "react";
import Title from "../components/Titles/TitleStyle";
import ArtGrid from "../components/ArtGrid/ArtGrid";
import Footer from "../components/Footer/Footer";
import { motion, useAnimation } from "framer-motion";
import { useAnimationObserver } from "../utils/animationObserver";

function Creations() {
  const artGridAnimation = useAnimation();
  const artGrid = useRef(null);

  useAnimationObserver(artGridAnimation, artGrid);

  return (
    <main style={{ backgroundColor: "var(--beige)" }}>
      <div data-scroll>
        <motion.div
          ref={artGrid}
          initial={{ y: 50, opacity: 0 }}
          animate={artGridAnimation}
        >
          <Title>
            <h1 style={{ margin: "0", paddingTop: "8rem" }}>
              <span>All Creations</span>
            </h1>
          </Title>
          <ArtGrid />
        </motion.div>
        <Footer />
      </div>
    </main>
  );
}

export default Creations;
