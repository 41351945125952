import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Hero from "../components/Hero/Hero";
import Card from "../components/Card/Card";
import Footer from "../components/Footer/Footer";
import Title from "../components/Titles/TitleStyle";
import PlayScroll from "../components/Playground/PlayScroll";
import { BtnWrapper, PrimaryBtn } from "../components/Button/PrimaryBtnStyle";
import { motion, useAnimation } from "framer-motion";
import { useAnimationObserver } from "../utils/animationObserver";

const Home = () => {
  const titleRef = useRef(null);

  const scrollToTitle = () => {
    window.scrollTo({
      top: titleRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const playScrollAnimation = useAnimation();
  const playScroll = useRef(null);

  useAnimationObserver(playScrollAnimation, playScroll);

  return (
    <main className="blackBg">
      <div data-scroll>
        <Hero onButtonClick={scrollToTitle} />
        <div className="whiteBg">
          <Title ref={titleRef}>
            <h2>Selected Work</h2>
          </Title>
          <Card showAll={false} />
          <BtnWrapper>
            <Link to="/work">
              <PrimaryBtn>View all work</PrimaryBtn>
            </Link>
          </BtnWrapper>
          <motion.div
            ref={playScroll}
            initial={{ y: 50, opacity: 0 }}
            animate={playScrollAnimation}
          >
            <PlayScroll />
          </motion.div>
        </div>
        <Footer />
      </div>
    </main>
  );
};

export default Home;
