import styled from "styled-components";
import { HiOutlineArrowDown } from "react-icons/hi";
import { keyframes } from "styled-components";

export const Container = styled.div`
  position: absolute;
  bottom: -5%;
  right: 0;

  @media screen and (max-width: 768px) {
    bottom: 3%;
  }
`;

const gradient = keyframes`
  0% {
    background-position: 0% 50%
  }
  100% {
    background-position: 100% 
  }

`;
export const Circle = styled.button`
  position: relative;
  width: 150px;
  height: 150px;
  border: none;
  background: linear-gradient(to right, #fbc70c 0%, #ffebcd 50%, #fbc70c 100%);
  background-size: 500%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    animation-name: ${gradient};
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  text {
    font-family: "blatant", sans-serif;
    font-size: 2.369rem;
    font-weight: 400;
    color: var(--black);
    letter-spacing: 0.024em;
  }

  path {
    fill: none;
  }
`;

export const ArrowIcon = styled(HiOutlineArrowDown)`
  position: absolute;
  color: var(--black);
  font-size: 3rem;
`;

export const TextImage = styled.div.attrs(({ rotation }) => ({
  style: {
    transform: `rotate(${rotation / 4}deg)`,
    transition: `all .45s linear`,
  },
}))`
  width: 125px;
  height: 125px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/images/scroll-down.png");
`;
