import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const SidebarContainer = styled.aside`
  position: absolute;
  z-index: 999;
  width: 100vw;
  height: calc(100vh - 5rem);
  top: 5rem;
  right: 0;
  transform: ${(props) =>
    props.isOpen ? "translateX(0)" : "translateX(100%)"};
  transition: transform 0.45s ease-in-out;
  background-color: ${({ pathname }) =>
    pathname.startsWith("/work/") ? "white" : "var(--beige)"};
`;

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
`;

export const SidebarLink = styled(LinkR)`
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  h1 {
    position: relative;
    margin: 2rem;
    transition: color 0.2s ease;

    &:hover {
      &::after,
      &::before {
        width: 100%;
        left: 0;
      }
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: calc(100% + 5px);
      width: 0;
      right: 0;
      height: 3px;
    }

    &::before {
      transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
      background: var(--yellow);
    }

    &::after {
      transition: width 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
      background: var(--blue);
    }
  }
`;
