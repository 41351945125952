import React from "react";
import { SidebarContainer, SidebarWrapper, SidebarLink } from "./SidebarStyle";

const Sidebar = ({ isOpen, pathname, toggle }) => {
  return (
    <SidebarContainer pathname={pathname} isOpen={isOpen}>
      <SidebarWrapper className="sidebarLinks">
        <SidebarLink onClick={toggle} to="work">
          <h1>Work</h1>
        </SidebarLink>
        <SidebarLink onClick={toggle} to="about">
          <h1>About</h1>
        </SidebarLink>
        <SidebarLink onClick={toggle} to="creations">
          <h1>Creations</h1>
        </SidebarLink>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
