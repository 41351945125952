import React, { useRef } from "react";
import Footer from "../components/Footer/Footer";
import AboutHero from "../components/Hero/AboutHero";
import { motion, useAnimation } from "framer-motion";
import { useAnimationObserver } from "../utils/animationObserver";

function About() {
  const aboutHeroAnimation = useAnimation();
  const aboutHero = useRef(null);

  useAnimationObserver(aboutHeroAnimation, aboutHero);

  return (
    <main>
      <div data-scroll>
        <motion.div
          ref={aboutHero}
          initial={{ y: 50, opacity: 0 }}
          animate={aboutHeroAnimation}
        >
          <AboutHero />
        </motion.div>
        <Footer />
      </div>
    </main>
  );
}

export default About;
