import React from "react";
import { Link } from "react-router-dom";
import {
  CardContainer,
  CardContent,
  ImageWrapper,
  ProjectImageContainer,
  ProjectImage,
  ProjectDetails,
  SkillContainer,
  SkillTag,
} from "./CardStyle";
import { SecondaryBtn } from "../Button/SecondaryBtnStyle";
import { projects } from "../../data/projects";

function Card({ showAll }) {
  const numProjectsToShow = showAll ? projects.length : 3;

  return (
    <>
      <CardContainer>
        {projects.slice(0, numProjectsToShow).map((project) => (
          <CardContent key={project.id}>
            <ImageWrapper className="wContainer">
              <ProjectImageContainer className="wImg">
                <Link key={project.id} to={`/work/${project.name}`}>
                  <ProjectImage
                    className="itemImg"
                    style={{ backgroundImage: `url(${project.heroImgSrc})` }}
                  ></ProjectImage>
                </Link>
              </ProjectImageContainer>
            </ImageWrapper>
            <ProjectDetails>
              <h3>{project.name}</h3>
              <p>{project.description}</p>
              <SkillContainer>
                {project.categories.map((category) => (
                  <SkillTag key={category}>{category}</SkillTag>
                ))}
              </SkillContainer>
              <Link key={project.id} to={`/work/${project.name}`}>
                <SecondaryBtn>Learn more</SecondaryBtn>
              </Link>
            </ProjectDetails>
          </CardContent>
        ))}
      </CardContainer>
    </>
  );
}

export default Card;
