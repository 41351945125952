import styled from "styled-components";

export const PrimaryBtn = styled.button`
  margin: 10px 0;
  padding: 16px 32px;
  background-color: transparent;
  border: 3px solid var(--black);
  border-radius: 5px;
  font-family: "Blatant", sans-serif;
  font-size: 1.333rem;
  font-weight: 400;
  color: var(--black);
  cursor: pointer;
  letter-spacing: 0.024em;
  transition: ease-out 0.5s;

  &:hover {
    color: white;
    box-shadow: inset 0 -100px 0 0 var(--black);
  }

  &:active {
    transform: scale(0.9);
  }
`;

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 6rem 0;

  @media screen and (max-width: 428px) {
    margin-bottom: 5rem;
  }
`;
