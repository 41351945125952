import "./App.css";
import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Work from "./pages/Work";
import ProjectPage from "./pages/ProjectPage";
import { projects } from "./data/projects";
import { smoothScrolling } from "./utils/smoothScrolling";
import Creations from "./pages/Creations";

function App() {
  const location = useLocation();

  // only apply scrolling effect if on home page
  useEffect(() => {
    // detects if on mobile device
    const userAgent = navigator.userAgent;
    const isMobile = /Mobile|Tablet/.test(userAgent);
    window.scrollTo(0, 0);
    if (!isMobile) {
      if (location.pathname === "/" || location.pathname.startsWith("/work")) {
        smoothScrolling();
      } else {
        document.body.style = `100vh`;
      }
    }
  }, [location.pathname]);

  // change background color for page
  useEffect(() => {
    document.body.className = location.pathname.replace("/", "");
  }, [location]);

  // preload images and videos
  useEffect(() => {
    const imagePaths = [
      "/images/selfPortrait.jpg",
      "/images/hero-illustration.png",
    ];

    const videoPaths = [
      "/images/barkable/barkable-recording.mp4",
      "/images/animalWeb/elephantRecording.mp4",
      "/images/bookApp/book-recording.mp4",
      "/images/precisionContractors/pc-recording.mp4",
    ];

    imagePaths.forEach((path) => {
      const image = new Image();
      image.src = path;
    });

    videoPaths.forEach((path) => {
      const video = document.createElement("video");
      video.src = path;
    });
  }, []);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/work" element={<Work />} />
        {projects.map((project) => (
          <Route
            key={project.id}
            path="/work/:name"
            element={<ProjectPage />}
          />
        ))}
        <Route path="/creations" element={<Creations />} />
      </Routes>
    </>
  );
}

export default App;
