import styled from "styled-components";

export const ContainerWrapper = styled.section`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.section`
  width: calc(100% - 5%);
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 428px) {
    width: calc(100% - 10%);
  }
`;

export const HeadingWrapper = styled.div`
  width: 70%;
  max-width: 1200px;
  padding: 8rem 0;
  display: flex;
  flex-direction: column;
  flex-direction: center;
  align-items: center;
  gap: 1rem;

  h3 {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 428px) {
    padding: 4rem 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  will-change: transform;
  margin: 1rem 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FullImageWrapper = styled.div`
  width: 100%;
  position: relative;
  will-change: transform;
`;

export const ImageContainer = styled.div`
  --aspect-ratio: 100/40;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
  /* max-width: calc(100% - 2rem); */
  max-width: 100%;
  will-change: transform;
  position: relative;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    --aspect-ratio: 60/40;
  }
`;

export const Image = styled.div`
  width: 100%;
  --overflow: 100px;
  height: calc(100% + (2 * var(--overflow)));
  top: calc(-1 * var(--overflow));
  position: absolute;
  background-image: var(--image);
  background-size: cover;
  background-position: 50% 50%;
  will-change: transform;

  @media screen and (max-width: 768px) {
    --overflow: 100px;
    background-position: 50% 0;
  }

  @media screen and (max-width: 428px) {
    --overflow: 50px;
  }
`;

export const VideoContainer = styled.div`
  --aspect-ratio: 100/40;
  overflow: hidden;
  width: 100vw;
  margin: 0 auto;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
  /* max-width: calc(100% - 2rem); */
  max-width: 100%;
  will-change: transform;
  position: relative;
  background-repeat: no-repeat;
  background-color: ${(props) => props.projectColor};
  display: flex;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    width: 45%;
    background-color: ${(props) => props.projectShadow};
    filter: blur(20px);
    height: 5rem;
    bottom: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    --aspect-ratio: 60/40;

    &::before {
      bottom: 3.5rem;
    }
  }
`;

export const Video = styled.video`
  width: 50%;
  --overflow: 100px;
  height: calc(100% + (2 * var(--overflow)));
  top: calc(-1 * var(--overflow));
  position: absolute;
  will-change: transform;
  border-radius: 50px;

  @media screen and (max-width: 768px) {
    width: 70%;
  }
`;
