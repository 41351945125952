import React, { useRef, useEffect } from "react";
import Card from "../components/Card/Card";
import Title from "../components/Titles/TitleStyle";
import { motion, useAnimation } from "framer-motion";
import { useAnimationObserver } from "../utils/animationObserver";
import RowProject from "../components/RowProject/RowProject";
import Footer from "../components/Footer/Footer";

function Work({ showAll }) {
  const workAnimation = useAnimation();
  const ref = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useAnimationObserver(workAnimation, ref);
  return (
    <>
      <main className="blackBg">
        <div data-scroll>
          <section
            style={{ backgroundColor: "var(--beige)", paddingTop: "5rem" }}
          >
            <motion.div
              ref={ref}
              initial={{ y: 50, opacity: 0 }}
              animate={workAnimation}
            >
              <Title>
                <h1>
                  <span style={{ backgroundPosition: "center 1.5rem" }}>
                    All Work
                  </span>
                </h1>
              </Title>
              <Card showAll={true} />
            </motion.div>
            <RowProject />
            <Footer />
          </section>
        </div>
      </main>
    </>
  );
}

export default Work;
