import React, { useState } from "react";
import { PanelContainer, Header, Title, Content } from "./ExpansionPanelStyle";

function ExpansionPanel({ title, children }) {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <PanelContainer>
      <Header onClick={handleToggle} expanded={expanded}>
        <Title>
          <h3> {title}</h3>
        </Title>
        <div className={`iconContainer ${expanded ? "expanded" : ""}`}>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </Header>
      <Content expanded={expanded}>{children}</Content>
    </PanelContainer>
  );
}

export default ExpansionPanel;
