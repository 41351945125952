import styled from "styled-components";
import { Link } from "react-router-dom";
// import { Link as LinkS } from 'react-scroll'

export const Nav = styled.nav`
  position: fixed;
  width: 100vw;
  padding: 1rem 0;
  /* height: 5rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100000;
  transition: all 0.4s;
  background-color: ${({ pathname }) =>
    pathname.startsWith("/work/") ? "white" : "var(--beige)"};
  top: ${(props) => (props.visible ? "0" : "-120px")};
`;

export const NavLogo = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 5%;

  img {
    width: 4rem;
    margin: auto 0;
  }
`;

export const HamburgerContainer = styled.div`
  display: none;
  margin-right: 5%;

  @media screen and (max-width: 768px) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    cursor: pointer;
    /* background-color: ${(props) =>
      props.isOpen === true ? "blue" : "green"}; */
    width: 40px;
    height: 35px;
    transition: all 0.5s ease-in-out;
  }
`;

export const HamburgerIcon = styled.div`
  cursor: pointer;

  svg {
    height: 3rem;
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line {
    fill: none;
    stroke: var(--black);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
    /* Define the transition for transforming the Stroke */
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line-top-bottom {
    stroke-dasharray: 12 63;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    svg {
      transform: rotate(-45deg);
    }
  
    .line-top-bottom {
      stroke-dasharray: 20 300;
      stroke-dashoffset: -32.42;
    }
  `}
`;

export const NavMenu = styled.ul`
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin-right: 5%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavLinks = styled(Link)`
  position: relative;
  margin-left: 60px;
  text-decoration: none;
  color: var(--black);
  transition: color 0.2s ease;

  &:hover {
    &::after,
    &::before {
      width: 100%;
      left: 0;
    }
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: calc(100% + 5px);
    width: 0;
    right: 0;
    height: 3px;
  }

  &::before {
    transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
    background: var(--yellow);
  }

  &::after {
    transition: width 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
    background: var(--blue);
  }
`;
