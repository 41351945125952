import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
`;

export const HeadingWrapper = styled.div`
  position: relative;
  width: calc(100% - 5%);
  max-width: 1200px;
  padding: 8rem 0;
  display: flex;
  flex-direction: column;

  h5 {
    line-height: 2;
  }
  h1 {
    margin: 0;
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    h1 {
      width: 100%;
      font-size: 3.157rem;
    }
  }

  @media screen and (max-width: 428px) {
    width: calc(100% - 10%);
    padding: 4rem 0;

    h1 {
      font-size: 2.369rem;
    }

    h5 {
      font-size: 1.125rem;
    }
  }
`;

export const BannerWrapper = styled.div`
  width: 100%;
  position: relative;
  will-change: transform;
`;

export const BannerImageContainer = styled.div`
  --aspect-ratio: 90/50;
  overflow: hidden;
  width: 100vw;
  margin: 0 auto;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
  /* max-width: calc(100% - 2rem); */
  max-width: 100%;
  will-change: transform;
  position: relative;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    --aspect-ratio: 70/50;
  }

  @media screen and (max-width: 428px) {
    --aspect-ratio: 60/50;
  }
`;

export const BannerImage = styled.div`
  width: 100%;
  --overflow: 200px;
  height: calc(100% + (2 * var(--overflow)));
  top: calc(-1 * var(--overflow));
  position: absolute;
  background-image: var(--image);
  background-size: cover;
  background-position: 50% 0%;
  will-change: transform;

  @media screen and (max-width: 768px) {
    --overflow: 100px;
  }

  @media screen and (max-width: 428px) {
    --overflow: 50px;
  }
`;

// Overview section
export const ProjectOverview = styled.div`
  width: calc(100% - 5%);
  max-width: 1200px;
  padding: 8rem 0;
  display: flex;
  justify-content: flex-start;

  h3 {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  @media screen and (max-width: 428px) {
    width: calc(100% - 10%);
    padding: 4rem 0;
  }
`;

export const Overview = styled.div`
  h6 {
    margin: 1rem 0 2rem 0;
    max-width: 800px;
  }

  @media screen and (max-width: 768px) {
    /* h6 {
      font-size: 1rem;
    } */
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  gap: 5rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    gap: 3rem;
  }

  @media screen and (max-width: 428px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const DetailWrapper = styled.div`
  display: flex;
  gap: 4rem;

  p.overline {
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    gap: 3rem;
  }
`;

export const TechStack = styled.div`
  min-width: 7rem;

  p {
    margin: 0;
  }
  h4 {
    margin-bottom: 16px;
  }
  li {
    list-style: none;
    line-height: 180%;
  }

  @media screen and (max-width: 768px) {
    min-width: 0;
  }
`;

export const Roles = styled.div`
  p {
    margin: 0;
  }
  h4 {
    margin-bottom: 16px;
  }
  li {
    list-style: none;
    line-height: 180%;
  }
`;

export const Categories = styled.div`
  min-width: 7rem;
  p {
    margin: 0;
  }
  h4 {
    margin-bottom: 16px;
  }
  li {
    list-style: none;
    line-height: 180%;
  }

  @media screen and (max-width: 768px) {
    min-width: 0;
  }
`;

export const Timeline = styled.div`
  p {
    margin: 0;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  gap: 2rem;

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }
`;
