import React, { useEffect, useState } from "react";
import { Container, Circle, ArrowIcon, TextImage } from "./ScrollDownStyle";

function ScrollDown({ onClick }) {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setRotation(scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Container>
        <Circle onClick={onClick}>
          <ArrowIcon />
          <TextImage rotation={rotation} />
        </Circle>
      </Container>
    </>
  );
}

export default ScrollDown;
