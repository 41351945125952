import styled from "styled-components";

export const GridContainer = styled.div`
  width: 100vw;
  display: flex;
  margin-bottom: 8rem;
  justify-content: center;
  align-items: center;
`;

export const GridWrapper = styled.div`
  width: calc(100% - 5%);
  max-width: 1200px;
  margin: 3% 5%;
  display: flex;
  align-items: flex-start;
  grid-gap: 4rem;

  @media screen and (max-width: 768px) {
    grid-gap: 1rem;
  }
`;

export const Column = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 4rem;

  @media screen and (max-width: 768px) {
    grid-gap: 1rem;
  }
`;

export const ArtImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
