import styled from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  counter-reset: figure;
  margin: 0 5%;
  gap: 15rem;

  @media screen and (max-width: 768px) {
    gap: 5rem;
  }
`;

export const CardContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 2rem;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;

    &:nth-child(even) {
      flex-direction: column;
    }
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 5px;
  will-change: transform;
`;

export const ProjectImageContainer = styled.div`
  position: relative;
  --aspect-ratio: 150/100;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
  border-radius: 5px;
  background-repeat: no-repeat;
  will-change: transform;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

  @media screen and (max-width: 428px) {
    --aspect-ratio: 120/100;
  }
`;

export const ProjectImage = styled.div`
  position: absolute;
  width: 100%;
  --overflow: 100px;
  height: calc(100% + (2 * var(--overflow)));
  top: calc(-1 * var(--overflow));
  background-image: var(--image);
  background-size: cover;
  background-position: 50% 0%;
  will-change: transform;

  @media screen and (max-width: 428px) {
    --overflow: 50px;
  }
`;

export const ProjectDetails = styled.div`
  width: 50%;
  align-self: center;

  h3 {
    margin: 10px 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const SkillContainer = styled.div`
  display: flex;
  margin: 1.5rem 0;
  gap: 0.5rem;
`;

export const SkillTag = styled.div`
  display: flex;
  background-color: var(--black);
  border-radius: 5px;
  padding: 7px 9px;
  color: white;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.0132em;
`;
