import React from "react";
import { DoubleStarContainer, StarContainer } from "./StarStyle";

function Star() {
  return (
    <>
      <StarContainer>
        <div className="star-1">
          <svg
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 784.11 815.53"
            style={{
              shapeRendering: "geometricPrecision",
              textRendering: "geometricPrecision",
              imageRendering: "optimizeQuality",
              fillRule: "evenodd",
              clipRule: "evenodd",
            }}
            version="1.1"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs />
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                d="M 392.05 0 c -21.05 259 -134.06 378.41 -392.05 407.78 c 257.96 29.37 371.12 147.68 392.05 407.74 c 21.05 -259 134.09 -378.37 392.05 -407.74 c -257.98 -29.38 -371.16 -147.69 -392.06 -407.78 z"
                className="fil0"
              />
            </g>
          </svg>
        </div>
        <div className="star-2">
          <svg
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 784.11 815.53"
            style={{
              shapeRendering: "geometricPrecision",
              textRendering: "geometricPrecision",
              imageRendering: "optimizeQuality",
              fillRule: "evenodd",
              clipRule: "evenodd",
            }}
            version="1.1"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs />
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                d="M 400 0 L 512 240 L 744 344 L 512 464 L 400 696 L 288 464 L 56 344 L 288 240 Z"
                className="fil0"
              />
            </g>
          </svg>
        </div>
        <div className="star-3">
          <svg
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 784.11 815.53"
            style={{
              shapeRendering: "geometricPrecision",
              textRendering: "geometricPrecision",
              imageRendering: "optimizeQuality",
              fillRule: "evenodd",
              clipRule: "evenodd",
            }}
            version="1.1"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs />
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                d="M 400 0 L 512 240 L 744 344 L 512 464 L 400 696 L 288 464 L 56 344 L 288 240 Z"
                className="fil0"
              />
            </g>
          </svg>
        </div>
      </StarContainer>
      <DoubleStarContainer>
        <div className="star-1">
          <svg
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 784.11 815.53"
            style={{
              shapeRendering: "geometricPrecision",
              textRendering: "geometricPrecision",
              imageRendering: "optimizeQuality",
              fillRule: "evenodd",
              clipRule: "evenodd",
            }}
            version="1.1"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs />
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                d="M392.05 0c-20.9,210.08 -184.06,378.41 -392.05,407.78 207.96,29.37 371.12,197.68 392.05,407.74 20.93,-210.06 184.09,-378.37 392.05,-407.74 -207.98,-29.38 -371.16,-197.69 -392.06,-407.78z"
                className="fil0"
              />
            </g>
          </svg>
        </div>
      </DoubleStarContainer>
    </>
  );
}

export default Star;
