import styled from "styled-components";

export const PanelContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--blue);

  .iconContainer {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 20px;
    user-select: none;
    width: 30px;
    height: 30px;
  }

  .line {
    width: calc(100% - 8px);
    height: 2px;
    left: 4px;
    background: var(--black);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.7s transform cubic-bezier(0, 1, 0.33, 1.2), background 0.4s;
  }

  .iconContainer.expanded .line:first-child {
    transform: translateY(-50%) rotate(-90deg);
  }

  @media screen and (max-width: 768px) {
    width: calc(100vw - 30%);
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ expanded }) => (expanded ? "1.5rem 0 0 0" : "1.5rem 0")};
  cursor: pointer;
`;

export const Title = styled.div`
  margin: 0;
`;

export const Content = styled.div`
  display: ${(props) => (props.expanded ? "block" : "none")};
`;
