import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useAnimationObserver } from "../utils/animationObserver";
import ProjectBanner from "../components/Project/ProjectBanner";
import ProjectHero from "../components/Project/ProjectHero";
import Footer from "../components/Footer/Footer";
import NextBanner from "../components/Project/NextBanner";
import { projects } from "../data/projects";
import { smoothScrolling } from "../utils/smoothScrolling";

const ProjectPage = () => {
  const { name } = useParams();
  const [project, setProject] = useState({});
  const [nextProject, setNextProject] = useState({});

  // detects if on mobile
  const userAgent = navigator.userAgent;
  const isMobile = /Mobile|Tablet/.test(userAgent);

  // finds the project id that was clicked and renders that project
  useEffect(() => {
    const currentIndex = projects.findIndex((p) => p.name === name);
    setProject(projects[currentIndex]);
    setNextProject(projects[(currentIndex + 1) % projects.length]);
    window.scrollTo(0, 0);
    if (!isMobile) {
      smoothScrolling();
    }
  }, [name, isMobile]);

  const projectAnimation = useAnimation();
  const projectHero = useRef(null);

  useAnimationObserver(projectAnimation, projectHero);

  return (
    <>
      <main>
        <div data-scroll>
          <motion.div
            ref={projectHero}
            initial={{ y: 50, opacity: 0 }}
            animate={projectAnimation}
          >
            <ProjectHero project={project} />
          </motion.div>
          <ProjectBanner project={project} />
          <NextBanner nextProject={nextProject} />
          <Footer />
        </div>
      </main>
    </>
  );
};

export default ProjectPage;
