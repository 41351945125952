import styled from "styled-components";
import { SecondaryBtn } from "../Button/SecondaryBtnStyle";

export const HeroContainer = styled.header`
  position: relative;
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--beige);
  padding-top: 5%;

  @media screen and (max-width: 768px) {
    height: 100%;
    padding-top: calc(5rem);
  }
`;

export const HeroContent = styled.div`
  position: relative;
  width: calc(100% - 5%);
  max-width: 1200px;
  margin: 3% 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  h5 {
    margin: 1rem 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0 5%;
    padding-bottom: 5rem;
    flex-direction: column;
    gap: 0;
  }
`;

export const HeroHeading = styled.div`
  position: relative;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h1 {
    font-size: 8rem;
    margin: 0;
    line-height: 1;
    z-index: 10;

    span {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: -1.8rem;
        background: url("/images/line.png");
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 2rem 0;

    h1 {
      font-size: 6rem;
    }
  }

  @media screen and (max-width: 428px) {
    h1 {
      font-size: 5rem;
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 29rem;
  display: flex;
  justify-content: center;

  /* Square image */
  &::before {
    content: " ";
    width: 100%;
    justify-self: center;
    background: url("/images/square.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  @media screen and (max-width: 768px) {
    height: 20rem;
  }

  img {
    position: absolute;
    width: clamp(210px, 330px, 460px);
    bottom: -50px;

    @media screen and (max-width: 768px) {
      width: 250px;
      bottom: -55px;
    }
  }
`;

export const TextContent = styled(SecondaryBtn)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin: 0;

  &::after {
    content: "";
    width: 0%;
  }

  h5 {
    margin: 0;
    font-size: 1.333rem;
    font-weight: 400;
    line-height: 1.6;
    font-family: "Sora", sans-serif;
    margin: 0;
    color: var(--black);
  }

  svg {
    transform: translateX(-8px);
    transition: all 0.3s ease;
    fill: var(--black);
    padding-bottom: 0.5rem;
  }

  &:hover svg {
    transform: translateX(0);
  }

  &:active svg {
    transform: scale(0.9);
  }

  .underline-animation {
    position: relative;
    padding-bottom: 0.5rem;
  }

  .underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--blue);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover .underline-animation:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;
