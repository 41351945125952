import React, { useState } from 'react';
import {
  FooterContainer,
  FooterContent,
  TextContent,
  LinkWrapper,
  StyledLink,
  ScrollUp,
  Copyright,
  ArrowIcon,
} from './FooterStyle';

const Footer = () => {
  const [isCopied, setIsCopied] = useState(false);
  const email = 'isabellatannady@gmail.com';
  const handleCopy = () => {
    navigator.clipboard.writeText('isabellatannady@gmail.com');
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000); // reset the tooltip text after 2 seconds
  };

  function backToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <>
      <FooterContainer>
        <FooterContent>
          <TextContent>
            <h2>Thanks for stopping by!</h2>
            <h6>
              But don't go just yet - let's connect! I’m always open to any
              exciting opportunities.
            </h6>
            <LinkWrapper>
              <StyledLink onClick={handleCopy}>
                <span className='tooltip'>
                  {isCopied ? 'Copied!' : 'Copy to clipboard?'}
                </span>
                {email}
              </StyledLink>
              <a
                href='https://www.linkedin.com/in/isabellatannady/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <StyledLink>LinkedIn</StyledLink>
              </a>
              <a
                href='https://github.com/itannady'
                target='_blank'
                rel='noopener noreferrer'
              >
                <StyledLink>Github</StyledLink>
              </a>
            </LinkWrapper>
          </TextContent>
          <ScrollUp onClick={backToTop}>
            <ArrowIcon />
          </ScrollUp>
        </FooterContent>
        <Copyright>
          <p className='caption'>Designed and developed by Isabella Tannady</p>
          <p className='caption'>
            © 2024 Isabella Tannady. All Rights Reserved.
          </p>
        </Copyright>
      </FooterContainer>
    </>
  );
};

export default Footer;
