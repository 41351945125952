import React, { useRef } from "react";
import { Link } from "react-router-dom";
import {
  HeroContainer,
  HeroContent,
  HeroHeading,
  ImageContainer,
  TextContent,
} from "./HeroStyle";
import ScrollDown from "../ScrollDown/ScrollDown";
import { motion, useAnimation } from "framer-motion";
import {
  useAnimationObserver,
  useDelayAnimationObserver,
} from "../../utils/animationObserver";

const Hero = ({ onButtonClick }) => {
  const firstAnimation = useAnimation();
  const secondAnimation = useAnimation();
  const header = useRef(null);

  useAnimationObserver(firstAnimation, header);
  useDelayAnimationObserver(secondAnimation, header);

  return (
    <>
      <HeroContainer>
        <HeroContent>
          <HeroHeading>
            <h5>Hi, I'm Isabella Tannady</h5>
            <motion.h1
              ref={header}
              initial={{ y: 50, opacity: 0 }}
              animate={firstAnimation}
            >
              Creative <br />
            </motion.h1>
            <motion.h1
              ref={header}
              initial={{ y: 50, opacity: 0 }}
              animate={secondAnimation}
            >
              <span>Developer</span>
            </motion.h1>
            <h5>
              Bridging the gap between design and development to create
              meaningful human-centered experiences
            </h5>
            <Link to="/about">
              <TextContent>
                <h5 className="underline-animation">Learn more about me</h5>
                <svg
                  viewBox="0 0 46 16"
                  height="10"
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                  id="arrow-horizontal"
                >
                  <path
                    transform="translate(30)"
                    d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                    data-name="Path 10"
                    id="Path_10"
                  ></path>
                </svg>
              </TextContent>
            </Link>
          </HeroHeading>
          <ImageContainer>
            <img
              src="/images/hero-illustration.png"
              alt="Self portrait illustration"
            ></img>
          </ImageContainer>
          <ScrollDown onClick={onButtonClick} />
        </HeroContent>
      </HeroContainer>
    </>
  );
};

export default Hero;
