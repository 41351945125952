import React from "react";
import {
  Container,
  Wrapper,
  HeaderContainer,
  ContentContainer,
  MiniCard,
  TitleWrapper,
  LinkWrapper,
  GithubIcon,
} from "./RowProjectStyle";
import { minis } from "../../data/minis";

function RowProject() {
  return (
    <>
      <Container>
        <Wrapper>
          <HeaderContainer>
            <h2>Mini Projects</h2>
          </HeaderContainer>
          <ContentContainer>
            {minis.map((mini) => (
              <MiniCard key={mini.id}>
                <a href={mini.link} target="_blank" rel="noopener noreferrer">
                  <img src={mini.heroImgSrc} alt="" />
                </a>
                <TitleWrapper>
                  <a href={mini.link} target="_blank" rel="noopener noreferrer">
                    <h5 className="miniProjectTitle">{mini.name}</h5>
                  </a>
                  <LinkWrapper>
                    <a
                      href={mini.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <GithubIcon />
                    </a>
                  </LinkWrapper>
                </TitleWrapper>
                <p>{mini.description}</p>
              </MiniCard>
            ))}
          </ContentContainer>
        </Wrapper>
      </Container>
    </>
  );
}

export default RowProject;
