import React, { useState, useEffect } from "react";
import { GridContainer, ArtImage, GridWrapper, Column } from "./ArtGridStyle";

function ArtGrid() {
  const art = [
    "/images/playImages/terrierAcrylic.jpg",
    "/images/playImages/pochita.jpg",
    "/images/playImages/goldenWatercolor.jpg",
    "/images/playImages/holiday-treats.jpg",
    "/images/playImages/calcifer.gif",
    "/images/playImages/catAcrylic.jpg",
    "/images/playImages/lofiGirl.gif",
    "/images/playImages/forest.jpg",
    "/images/playImages/angel.jpg",
    "/images/playImages/yoda.jpg",
    "/images/playImages/kikiAnimation.gif",
    "/images/playImages/spanielWatercolor.jpg",
    "/images/playImages/pitbullAcrylic.jpg",
    "/images/playImages/pomWatercolor.jpg",
    "/images/playImages/guardian-fanart.jpg",
    "/images/playImages/wedding-invite.jpg",
    "/images/playImages/house.jpg",
    "/images/playImages/zenitsu.jpg",
    "/images/playImages/bernedoodleWatercolor.jpg",
  ];

  const [numColumns, setNumColumns] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768 && numColumns !== 2) {
        setNumColumns(2);
      } else if (screenWidth > 768 && numColumns !== 3) {
        setNumColumns(3);
      }
    };

    window.addEventListener("resize", handleResize);
    // call handleResize on mount
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [numColumns]);

  const columns = Array.from({ length: numColumns }, () => []);

  art.forEach((artwork, index) => {
    const shortestColumnIndex = columns.reduce(
      (shortestIndex, column, currentIndex) => {
        if (column.length < columns[shortestIndex].length) {
          return currentIndex;
        }
        return shortestIndex;
      },
      0
    );
    columns[shortestColumnIndex].push(artwork);
  });

  return (
    <>
      <GridContainer>
        <GridWrapper>
          {columns.map((columnArtworks, columnIndex) => (
            <Column key={columnIndex}>
              {columnArtworks.map((artwork, index) => (
                <ArtImage key={index} src={artwork} />
              ))}
            </Column>
          ))}
        </GridWrapper>
      </GridContainer>
    </>
  );
}

export default ArtGrid;
