import styled from "styled-components";

const Title = styled.div`
  position: relative;
  padding: 3% 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3.157rem;
  font-weight: 400;

  h1,
  h2,
  h3 {
    width: 100%;
    max-width: 1200px;

    span {
      position: relative;
      background: url("/images/line.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% -20%;
    }
  }
`;

export default Title;
