import styled from "styled-components";

export const PlayContainer = styled.section`
  position: relative;
  width: 100vw;
  padding: 12rem 0 4rem 0;
  overflow-x: hidden;

  @media screen and (max-width: 428px) {
    padding: 4rem 0 2rem 0;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  h2 {
    position: relative;
    text-align: center;
    margin: 0;
    z-index: 100;

    &::before {
      content: " ";
      position: absolute;
      width: 10rem;
      height: 10rem;
      background: url("/images/tags/creativeTag.png");
      background-repeat: no-repeat;
      background-size: contain;
      top: -1rem;
      left: -8.5rem;
      z-index: -1;
      transform: rotate(-0.01turn);

      @media screen and (max-width: 428px) {
        width: 50%;
        top: -1rem;
        left: -2.5rem;
        transform: rotate(-0.02turn);
      }
    }
  }
`;

export const PlayWrapper = styled.div`
  position: relative;
  width: 100vw;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 428px) {
    margin-top: 5rem;
  }
`;

export const Row = styled.div`
  width: 100vw;
  height: 30rem;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  &:nth-child(1) {
    margin-bottom: 10rem;
  }

  @media (min-width: 1920px) {
    height: 40rem;
  }

  @media screen and (max-width: 768px) {
    height: 22rem;

    &:nth-child(1) {
      margin-bottom: 5rem;
    }
  }

  @media screen and (max-width: 428px) {
    height: 15rem;

    &:nth-child(1) {
      margin-bottom: 5rem;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 6rem;

  @media (min-width: 1920px) {
    gap: 9rem;
  }

  @media screen and (max-width: 768px) {
    gap: 3rem;
  }

  @media screen and (max-width: 428px) {
    gap: 1.5rem;
  }
`;

export const Image = styled.div`
  overflow: hidden;

  &:nth-child(even) {
    align-self: flex-end;
  }

  img {
    width: 20rem;
    border-radius: 5px;

    @media (min-width: 1920px) {
      width: 25rem;
    }

    @media screen and (max-width: 768px) {
      width: 15rem;
    }

    @media screen and (max-width: 428px) {
      width: 10rem;
    }
  }
`;
