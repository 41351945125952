import styled from "styled-components";

export const SecondaryBtn = styled.button`
  position: relative;
  border: none;
  background-color: transparent;
  font-family: "Blatant", sans-serif;
  font-size: 1.333rem;
  font-weight: 400;
  letter-spacing: 0.018em;
  color: var(--black);
  margin: 10px 0;
  line-height: 2;
  padding: 0;
  cursor: pointer;

  &::after {
    content: "";
    width: 100%;
    pointer-events: none;
    bottom: -2px;
    right: 0;
    position: absolute;
    height: 2px;
    background-color: var(--blue);
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
  }

  &:hover:after {
    width: 0%;
    right: 50%;
  }

  &:hover {
    color: var(--gray);
  }
`;
