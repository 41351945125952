import styled from "styled-components";

export const StarContainer = styled.div`
  .star-1 {
    position: absolute;
    bottom: -20%;
    right: -2%;
    width: 8rem;
    height: auto;
    filter: drop-shadow(0 0 0 #fff8dd);
    z-index: 5;
    fill: var(--yellow);
    transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);

    @media screen and (max-width: 768px) {
      bottom: -12%;
      right: 0;
    }

    @media screen and (max-width: 428px) {
      bottom: -10%;
      width: 6rem;
      right: 5%;
    }
  }

  .star-2 {
    position: absolute;
    bottom: -8%;
    right: 1%;
    width: 2.5rem;
    height: auto;
    filter: drop-shadow(0 0 0 #fff8dd);
    z-index: -5;
    fill: var(--yellow);
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }

  .star-3 {
    position: absolute;
    bottom: -8%;
    right: 1%;
    width: 2rem;
    height: auto;
    filter: drop-shadow(0 0 0 #fff8dd);
    z-index: -5;
    fill: var(--yellow);
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }

  &:hover {
    .star-2 {
      position: absolute;
      bottom: 10%;
      right: -2%;
      width: 2.5rem;
      height: auto;
      filter: drop-shadow(0 0 10px #fffdef);
      z-index: 3;
      fill: var(--yellow);
      transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
    }

    .star-3 {
      position: absolute;
      bottom: 2%;
      right: -4%;
      width: 1.5rem;
      height: auto;
      filter: drop-shadow(0 0 10px #fffdef);
      z-index: 3;
      fill: var(--yellow);
      transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
    }
  }
`;

export const DoubleStarContainer = styled.div`
  .star-1 {
    position: absolute;
    top: -5%;
    left: -2%;
    width: 3rem;
    height: auto;
    filter: drop-shadow(0 0 0 #fff8dd);
    z-index: 5;
    fill: var(--yellow);
    transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
    transform: rotate(-20deg);

    @media screen and (max-width: 768px) {
      top: 35%;
    }
    @media screen and (max-width: 428px) {
      width: 3rem;
      top: 23%;
    }

    &:hover {
      transform: rotate(360deg);
    }
  }
`;
