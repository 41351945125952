import styled from "styled-components";
import { HiOutlineArrowUp } from "react-icons/hi";
import { SecondaryBtn } from "../Button/SecondaryBtnStyle";
import { keyframes } from "styled-components";

export const FooterContainer = styled.footer`
  position: relative;
  width: 100vw;
  background: var(--black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

export const FooterContent = styled.div`
  width: calc(100% - 10%);
  max-width: 1200px;
  display: flex;
  margin-top: 5%;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  h2,
  h6 {
    width: 80%;
    margin: 0;
    color: var(--beige);
  }

  @media screen and (max-width: 768px) {
    gap: 0;
    h2 {
      font-size: 2.369rem;
    }
  }

  @media screen and (max-width: 428px) {
    margin-top: 4rem;
    h2,
    h6 {
      width: 100%;
    }
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;

  gap: 2rem;
  margin-top: 0.5rem;

  @media screen and (max-width: 428px) {
    flex-wrap: wrap;
  }
`;

const gradient = keyframes`
  0% {
    background-position: 0% 50%
  }
  100% {
    background-position: 100% 
  }

`;

export const StyledLink = styled(SecondaryBtn)`
  position: relative;
  color: var(--beige);

  span.tooltip {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 0rem;
    left: 8rem;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 1rem;
    color: var(--black);
    background: var(--beige);
    padding: 0 0.5rem;
    border-radius: 5px;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    z-index: 999;
  }
  /* 
  span.tooltip::before {
    content: attr(data-text-initial);
  } */

  span.tooltip::after {
    content: "";
    position: absolute;
    background: inherit;
    left: 50%;
    z-index: 99999;
    pointer-events: none;
  }

  /* actions */
  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
    top: -2rem;
    left: 8rem;
  }

  &:hover {
    color: var(--gray-light);
  }
`;

export const ScrollUp = styled.button`
  min-width: 5rem;
  height: 5rem;
  border: none;
  background: linear-gradient(to right, #fbc70c 0%, #ffebcd 50%, #fbc70c 100%);
  background-size: 500%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;

  &:hover {
    animation-name: ${gradient};
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @media screen and (max-width: 428px) {
    align-self: flex-start;
  }
`;

export const ArrowIcon = styled(HiOutlineArrowUp)`
  position: absolute;
  color: var(--black);
  font-size: 2rem;
`;

export const Copyright = styled.div`
  width: calc(100% - 10%);
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  p {
    color: var(--gray-light);
    font-weight: 300;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
