import styled from "styled-components";
import { FaGithub } from "react-icons/fa";

export const Container = styled.section`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8rem 0;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 3% 5%;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 3% 0;

  h2 {
    position: relative;
    text-align: center;
    margin: 0;
    z-index: 100;

    &::before {
      content: " ";
      position: absolute;
      width: 8rem;
      height: 8rem;
      background: url("/images/tags/extraTag.png");
      background-repeat: no-repeat;
      background-size: contain;
      top: -2rem;
      right: -6rem;
      z-index: -1;
      transform: rotate(0.01turn);

      @media screen and (max-width: 768px) {
        width: 35%;
        top: -2rem;
        right: -1rem;
        transform: rotate(0.02turn);
      }
    }
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;

  @media screen and (max-width: 768px) {
    gap: 5rem;
    flex-direction: column;
  }
`;

export const MiniCard = styled.div`
  width: calc(100% / 3);
  min-height: 22rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  img {
    width: 100%;
    border-radius: 5px;
    max-height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease-in-out;

    &:hover {
      transform: scale(0.98);
    }
  }

  p {
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    img {
      height: 50%;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .miniProjectTitle {
    font-family: "Blatant", sans-serif;
    font-size: 1.333rem;
    font-weight: 400;
    color: var(--black);
    line-height: 1;
    margin: 0;
    transition: ease-in-out 0.3s;

    &:hover {
      color: var(--gray);
    }
  }
`;

export const GithubIcon = styled(FaGithub)`
  color: var(--black);
  font-size: 1.333rem;
  cursor: pointer;
  transition: ease-in-out 0.3s;

  &:hover {
    color: var(--gray);
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
