import { useEffect } from "react";

export const useAnimationObserver = (animationControls, ref) => {
  useEffect(() => {
    const element = ref.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animationControls.start({
            y: 0,
            opacity: 1,
            transition: { duration: 0.5 },
          });
        }
      });
    });
    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [animationControls, ref]);
};

// delay animation
export const useDelayAnimationObserver = (animationControls, ref) => {
  useEffect(() => {
    const element = ref.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animationControls.start({
            y: 0,
            opacity: 1,
            transition: { delay: 0.5, duration: 0.5 },
          });
        }
      });
    });
    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [animationControls, ref]);
};
